import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const files = require.context(`@/views`, true, /store.config.js$/);
let storeModules = {};
files.keys().forEach(key => {
  const name = (/\/(.+)\/store.config.js/.exec(key))[1];
  const storeModule = files(key).default || files(key);
  storeModules[name] = storeModule;
})

export default new Vuex.Store({
  state: {
    // ...Store.state,
  },
  getters: {
    // ...Store.getters,
  },
  actions: {
    // ...Store.actions,
  },
  mutations: {
    // ...Store.mutations,
  },
  modules:  { 
    namespaced: true, 
    ...storeModules
  }
});
