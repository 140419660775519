const Balances = r => require.ensure([], () => r(require("./Balances.vue")), "views/finance/Balances");
const SalesToPayout = r => require.ensure([], () => r(require("./SalesToPayout.vue")), "views/finance/SalesToPayout");

export default [
  {
    path: '/Finance/Balances_Company_',
    name: 'Balances(Company)',
    component: Balances
  },
  {
    path: '/Finance/Sales to payout',
    name: 'SalesToPayout',
    component: SalesToPayout
  }
]