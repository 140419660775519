import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/main.css';
// import '@/assets/js/papaparse.min.js';
import router from './router/index.js';
import store from './store/index.js';
import Element from 'element-ui'
import xss from './util/xss/index.js';
import axios from './util/axios/index.js';
import common from './util/common/index.js';   //公共方法
import components from './components/index.js'; //注册公共组件
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(Element);
Vue.use(xss);
Vue.use(axios);
Vue.use(common);
Vue.use(components);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
