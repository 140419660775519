
import './xss.min.js';
//引入xss.min.js后会抛出window.filterXSS
const xss = window.filterXSS;
// 重置xss白名单
let xssOptions = {
  whiteList: {
    ...xss.whiteList,
    a: ['style', 'class','href', 'title', 'target'],
    div: ['style', 'class'],
    span: ['style', 'class'],
    p: ['style', 'class'],
    h1: ['style', 'class'],
    h2: ['style', 'class'],
    h3: ['style', 'class'],
    h4: ['style', 'class'],
    h5: ['style', 'class'],
    h6: ['style', 'class'],
    ul: ['style', 'class'],
    li: ['style', 'class'],
    oi: ['style', 'class'],
    i: ['style', 'class'],
    strong: ['style', 'class'],
    em: ['style', 'class'],
    s: ['style', 'class'],
    u: ['style', 'class'],
    sup: ['style', 'class'],
    sub: ['style', 'class'],
    img: [...xss.whiteList.img, 'style', 'class'],
  },
  onTagAttr: function (tag, name, value) {
    if (tag == 'img') { //对于图片src再处理
      if (name == 'src') {
        if (/^data:image\/(\w{3,4});base64,/.test(""+value)) {
          let tempVal = xss(value)
          return `${name}="${tempVal}"`
        } else {
          return `${name}=""`
        }
      }
    }
  },
  onTag: function (tag) {
    let noTags = ['iframe', 'frameset', 'layer', 'meta', 'style', 'link', 'body', 'bgsound', 'script']
    if (noTags.includes(tag.toLowerCase())) {
      return ""
    }
  }
}

function install(Vue){
  let myxss = new xss.FilterXSS(xssOptions)
  let $xss = function (v) {
    return myxss.process(v)
  }
  Vue.prototype.$xss = $xss;
}

export default install;