import Vue from "vue";
import vueRouter from "vue-router";
Vue.use(vueRouter);

const files = require.context(`@/views`, true, /router.config.js$/);
let routers = [];
files.keys().forEach(key => {
  const router = files(key).default || files(key);
  routers = routers.concat(router);
})

export default new vueRouter({
  routes: routers
});
