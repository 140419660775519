<template>
  <div id="app">
    <div class="logo-title">
      <img src="@/assets/image/logo.png">
      <p v-show="!setName" @click="setName = true" >{{userName}}</p>
      <el-input v-show="setName" v-model="userName" @blur="saveUserName"></el-input>
    </div>
     <!-- @open="handleOpen"
        @close="handleClose" -->
    <el-scrollbar class="slide">
      <el-menu
        style="height: calc(100vh - 64px);"
        default-active="0"
        class="el-menu-vertical-demo "
        unique-opened
        router
        background-color="#00112c"
        text-color="#a5afbd"
        active-text-color="#fff"
        active-ba-color="#fff">
        <template v-for="(item,index) in menuData">
          <el-submenu v-if="item.children&&item.children.length>0" :key="index" :index="`${index}`">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item-group >
              <el-menu-item v-for="(name,i) in item.children" :key="i" :index="setRoute(`/${item.name}/${name}`)" :disabled="noDisabled[name] == undefined">{{ name }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-else :key="index" :index="setRoute(`/${item.name}`)" :disabled="noDisabled[item.name] == undefined">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
    <div class="top-bar">
      <el-input v-model="search.payment" placeholder="Search Payments">
        <i slot="append" class="el-icon-search"></i>
      </el-input>
      <i class="el-icon-bell"></i>
      <i class="el-icon-bangzhu"></i>
      <i class="el-icon-warning-outline"></i>
    </div>
    <div class="app-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
      setName: false,
      userName: '',
      search: {},
      noDisabled: {
        'Homepage': true,
        'Payments': true,
        'Balances(Company)': true,
        'Sales to payout': true
      },
      menuData: [
        {
          icon: 'el-icon-odometer',
          name: 'Homepage'
        },
        {
          icon: 'el-icon-film',
          name: 'Transactions',
          children: ['Offersactions', 'Payments']
        },
        {
          icon: 'el-icon-warning',
          name: 'Risk',
          children: [
            'Block and trust lists', 
            'Case management', 
            'Custom fields', 
            'Disputes', 
            'Experiments', 
            'Dynamic 3D Secure',
            'Risk profiles',
            'Settings'
          ]
        },
        {
          icon: 'el-icon-s-finance',
          name: 'Finance',
          children: [
            'Balances(Company)', 
            'Invoice overview', 
            'MPL', 
            'Payout accounts', 
            'Payout model', 
            'Reporting manager',
            'Sales to payout'
          ]
        },
        {
          icon: 'el-icon-data-analysis',
          name: 'Insights',
          children: [
            'Omnichannel shoppers', 
            'Payment lifecycle', 
            'Performance comparison', 
            'Risk & dispute management', 
            'Shopper origin'
          ]
        },
        {
          icon: 'el-icon-document',
          name: 'Reports',
        },
        {
          icon: 'el-icon-s-shop',
          name: 'Point of sale',
          children: [
            'Android', 
            'Orders and returns', 
            'Release notes and libraries', 
            'Stores', 
            'Terminal settings', 
            'Terminals'
          ]
        },
        {
          icon: 'el-icon-link',
          name: 'Pay by Link',
          children: [
            'Payment links', 
            'Themes', 
            'Setting'
          ]
        },
        {
          icon: 'el-icon-monitor',
          name: 'Developers',
          children: [
            'API credentials', 
            'API logs', 
            'API URLs', 
            'Additional data', 
            'Webhooks', 
            'Event logs'
          ]
        },
        {
          icon: 'el-icon-setting',
          name: 'Settings',
          children: [
            'Account groups', 
            'Audit trail', 
            'Checkout settings', 
            'Themes', 
            'Close account', 
            'Transaction description',
            'Account settings',
            'Merchant accounts',
            'Payment methods',
            'Report columns',
            'Users',
            'Partner users'
          ]
        },
      ]
    }
  },
  created(){
    this.userName = window.localStorage.getItem('userName') || 'Jinmaisui';
  },
  methods: {
    setRoute(url){
      return url.replace(/[()]/g, '_')
    },
    saveUserName(){
      this.setName = false;
      window.localStorage.setItem('userName', this.userName)
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .el-submenu {
    margin-bottom: 2px;
  }
  .el-menu-item, .el-submenu__title {
    height: 32px;
    line-height: 32px;
    &:hover {
      color: #fff !important;
      background: #20304c !important;
    }
  }
  .el-menu-item.is-active {
    background: #20304c !important;
  }
  .el-scrollbar__wrap {
    width: 100%;
    overflow: auto;
  }
  .logo-title {
    width: 249px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background: #00112c;
    img {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      margin-right: 12px;
    }
    p {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      font-family: fakt,-apple-system,blinkmacsystemfont,"Segoe UI",roboto,oxygen,ubuntu,cantarell,"Open Sans","Helvetica Neue",sans-serif;
    }
  }
  .slide {
    width: 250px;
    height: calc(100vh - 64px);
    color: #fff;
  }
  .top-bar {
    position: absolute;
    top: 0;
    left: 250px;
    width: calc(100vw - 250px);
    height: 64px;
    background: #fff;
    border-bottom: 1px solid #d2dae1;
    color: #00112c;
    padding-left: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    .el-input {
      width: 240px;
      .el-input__inner,.el-input-group__append {
        border-top: none;
        border-right: none;
        border-left: none;
      }
    }
    .el-input-group__append {
      background: #fff;
      padding: 2px 0 0 0;
    }
    i {
      display: inline-block;
      width: 40px;
      text-align: center;
      cursor: pointer;
      color: #90a2bd;
      font-weight: bold;
    }
  }
  .app-container {
    position: absolute;
    top: 64px;
    left: 250px;
    width: calc(100vw - 250px);
    padding: 20px 32px;
    height: calc(100vh - 64px);
    overflow-y: auto;
    .home-content {
        width: 100%;
        display: flex;
        .left-content{
            width: 60%;
            margin-right: 20px;
        }
        .right-content{
            width: 40%;
        }
        .content-link-box {
            display: flex;
            justify-content: space-between;
        }
        .content-link {
            width: 100%;
            line-height: 30px;
            color: #69778c;
            cursor: pointer;
            font-size: 13px;
        }
        .content-link:hover {
            text-decoration: underline;
        }
        .content-link::after {
            font-family: element-icons!important;
            content: '\e6e0';
            margin-left: 5px;
            font-weight: bold;
            color: #69778c;
        }
        .content-ul {
            margin-top: 10px;
            padding-left: 20px;
            line-height: 24px;
        }
        .message-box {
          .message-item {
            padding: 6px 0 8px 0;
            border-bottom: 1px solid #999;
            i {
                color: #999;
                font-size: 13px;
                ::before{
                    content: '···';
                    font-size: 24px;
                    margin-right: 8px;
                }
            }
            p {
                font-size: 13px;
                margin-top: 6px;
                margin-bottom: 6px;
                cursor: pointer;
                color: #000;
                &:hover {
                  text-decoration: underline;
                }
            }
            span {
                color: #999;
            }
          }
        }
    }
  }
}
</style>
