const state = {
  main: 'mainTest'
}

const getters = {
  getMain(state) {
    return state.main;
  }    
}

const actions = {
  setMain (context) {
    context.commit('setMain')
  }
}

const mutations = {
  setMain(state, val){
    state.demo = val;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}