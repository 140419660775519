import axios from 'axios';

var instance = axios.create();

instance.defaults.timeout = 60000; //前端超时限制
instance.defaults.headers.common['test'] = 'test'; //设置默认请求头

//设置axios的请求前拦截
instance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error)
  }
)

//设置axios的返回拦截
instance.interceptors.response.use(
  response => {
    return response;     
  },
  error => {
    return Promise.reject(error);    
  }    
)

function install(Vue){
  Vue.prototype.$http = instance;
}

export default install;