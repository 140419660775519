const Index = r => require.ensure([], () => r(require("./Index.vue")), "views/homepage/Index");

export default [
  {
    path: '/',
    redirect: '/Homepage'
  },
  {
    path: '/Homepage',
    name: 'mainIndex',
    component: Index
  }
]