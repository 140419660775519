import $dayjs from "./dayjs.min.js";

const $common = function(vmVue){
  console.log(vmVue)  
  let $methods = {
    dayjs: $dayjs,
    copy(obj){
      var newObj = null;
      if(typeof obj !== 'object' || obj === null){   
        return obj;	 //如果传入obj不是object或者为null就退出递归,
      }
      newObj = Array.isArray(obj) ? [] : {};	
            
      for(var key in obj){
        newObj[key] = arguments.callee(obj[key]);  //调用函数本身
      }
      return newObj;
    },
  }
  return $methods;
}

function install(Vue){
  Vue.prototype.$common = $common(Vue);
}

export default install;